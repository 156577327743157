import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { AlertConfirm, Spinner } from '../../../components';
import { Draggable, Droppable } from 'react-drag-and-drop';
import {
  loadTasksLists,
  saveTasksList,
  removeTasksList,
  saveTask,
  removeTask
} from '../../../helpers/actions/projects';
import TaskListModal from './TaskListModal';
import TaskModal from './TaskModal';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lists: [],
      editList: null,
      deleteList: null,
      editTask: null,
      deleteTask: null
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));

    let { dispatch, projectId } = this.props;
    dispatch(loadTasksLists(projectId))
      .then(lists => this.setState(ps => ({ ...ps, lists, loading: false })))
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  saveTaskList(list) {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    delete list.user;
    delete list.project;
    delete list.tasks;
    dispatch(saveTasksList(projectId, list))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  removeTaskList() {
    this.setState({ loading: true });
    let { deleteList } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removeTasksList(projectId, deleteList))
      .then(() => this.setState({ deleteList: null }, () => this.load()))
      .catch(() => this.setState({ deleteList: null, loading: false }));
  }

  saveTask(task) {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(saveTask(projectId, task.listId, task))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  removeTask() {
    let { deleteTask } = this.state,
      { dispatch, projectId } = this.props;

    if (deleteTask && deleteTask.listId && deleteTask.taskId) {
      this.setState({ loading: true });

      dispatch(removeTask(projectId, deleteTask.listId, deleteTask.taskId))
        .then(() => this.setState({ deleteTask: null }, () => this.load()))
        .catch(() => this.setState({ deleteTask: null, loading: false }));
    }
  }

  onDropTask(data, listTo) {
    data = data && data.task ? JSON.parse(data.task) : null;

    if (
      data &&
      data.listFrom !== null &&
      data.taskIndex !== null &&
      listTo !== null &&
      data.listFrom !== listTo
    ) {
      this.setState({ loading: true });

      let { listFrom, taskIndex } = data;
      let { lists } = this.state,
        { projectId, dispatch } = this.props;
      let promises = [];

      if (
        lists[listFrom] &&
        lists[listFrom].tasks &&
        lists[listFrom].tasks[taskIndex] &&
        lists[listTo]
      ) {
        let entity = cloneDeep(lists[listFrom].tasks[taskIndex]);
        delete entity.user;
        delete entity._id;

        promises.push(
          dispatch(
            removeTask(
              projectId,
              lists[listFrom]._id,
              lists[listFrom].tasks[taskIndex]._id
            )
          )
        );
        promises.push(dispatch(saveTask(projectId, lists[listTo]._id, entity)));

        Promise.all(promises)
          .then(() => this.setState({ loading: false }, () => this.load()))
          .catch(() => this.setState({ loading: false }));
      } else {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    let {
        loading,
        lists,
        editList,
        deleteList,
        editTask,
        deleteTask
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <Button
              size="sm"
              color={'info'}
              onClick={() => this.setState({ editList: {} })}
            >
              <Trans>Add Task List</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            <Row>
              {lists.map((list, key) => {
                return (
                  <Col key={key} xs={12} md={4}>
                    <Droppable
                      types={['task']}
                      className="card card-board-list card-grey"
                      onDrop={data => this.onDropTask(data, key)}
                    >
                      <CardHeader>
                        <h6 className="tasks-list-title">{list.name}</h6>
                        <UncontrolledDropdown>
                          <DropdownToggle className="btn-icon btn-sm btn-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                this.setState(ps => ({
                                  ...ps,
                                  editTask: { listId: list._id, subTasks: null }
                                }))
                              }
                            >
                              <Trans>Add Task</Trans>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                this.setState(ps => ({ ...ps, editList: list }))
                              }
                            >
                              <Trans>Edit Tasks List</Trans>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                this.setState(ps => ({
                                  ...ps,
                                  deleteList: list._id
                                }))
                              }
                            >
                              <Trans>Delete Tasks List</Trans>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </CardHeader>
                      <CardBody className="py-1">
                        {list.tasks
                          ? list.tasks.map((task, keyTask) => {
                              return (
                                <Draggable
                                  key={`list${key}-task${keyTask}`}
                                  className="card mb-2 draggable"
                                  type="task"
                                  data={`{ "listFrom":${key}, "taskIndex":${keyTask}}`}
                                >
                                  <CardBody className="py-2">
                                    <Row>
                                      <Col xs={12} md={9}>
                                        <p className="mb-0">{task.title}</p>
                                        {task.subTasks ? (
                                          <p className="card-category mb-0 pl-2">
                                            <span>
                                              <i className="far fa-check-square mr-2" />
                                            </span>
                                            {`${
                                              filter(task.subTasks, {
                                                completed: true
                                              }).length
                                            }/${task.subTasks.length} ${t(
                                              'Subtasks'
                                            )}`}
                                          </p>
                                        ) : (
                                          <p className="card-category mb-0 pl-2">
                                            <Trans>No subtasks</Trans>
                                          </p>
                                        )}
                                      </Col>
                                      <Col
                                        xs={12}
                                        md={3}
                                        className="text-right"
                                      >
                                        <Button
                                          className={
                                            'm-0 btn-round btn-icon btn-icon-mini btn-neutral'
                                          }
                                          color="info"
                                          onClick={() =>
                                            this.setState(ps => ({
                                              ...ps,
                                              editTask: {
                                                ...task,
                                                listId: list._id
                                              }
                                            }))
                                          }
                                        >
                                          <i className="fa fa-pencil-alt icon-action"></i>
                                        </Button>
                                        <Button
                                          className={
                                            'm-0 btn-round btn-icon btn-icon-mini btn-neutral'
                                          }
                                          color="danger"
                                          onClick={() =>
                                            this.setState(ps => ({
                                              ...ps,
                                              deleteTask: {
                                                listId: list._id,
                                                taskId: task._id
                                              }
                                            }))
                                          }
                                        >
                                          <i className="fas fa-trash-alt icon-action"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Draggable>
                              );
                            })
                          : null}
                      </CardBody>
                    </Droppable>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>

        {!!editList ? (
          <TaskListModal
            show={!!editList}
            list={editList}
            projectId={this.props.projectId}
            onCancel={() => this.setState(ps => ({ ...ps, editList: null }))}
            onConfirm={entity =>
              this.setState(
                ps => ({ ...ps, editList: null }),
                () => this.saveTaskList(entity)
              )
            }
          />
        ) : null}

        {deleteList ? (
          <AlertConfirm
            message={'The task list cannot be recovered'}
            onCancel={() => this.setState({ deleteList: null })}
            onConfirm={() => this.removeTaskList()}
          />
        ) : null}

        {!!editTask ? (
          <TaskModal
            show={!!editTask}
            task={editTask}
            projectId={this.props.projectId}
            onCancel={() => this.setState(ps => ({ ...ps, editTask: null }))}
            onConfirm={task =>
              this.setState(
                ps => ({ ...ps, editTask: null }),
                () => this.saveTask(task)
              )
            }
          />
        ) : null}

        {deleteTask ? (
          <AlertConfirm
            message={'The task cannot be recovered'}
            onCancel={() => this.setState({ deleteTask: null })}
            onConfirm={() => this.removeTask()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Board));
