import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { Slider } from '../../../components';
import get from 'lodash/get';
import { supervisorName } from '../../../helpers/formatters';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { isMobileOnly } from 'react-device-detect';

class AssignSupervisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {}
    };
  }

  componentWillReceiveProps({ project }) {
    this.setState({ project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  createSlider(project) {
    return project.supervisors.map(s => (
      <div key={s._id}>
        <div
          className="project-detail-avatar"
          style={{
            backgroundImage: `url('${s.photoURL ? s.photoURL : defaultAvatar}')`
          }}
        />
        <CardTitle tag="h5" className={'text-center'}>
          {supervisorName(s, '-')}
        </CardTitle>
        {s ? (
          <div>
            <ul>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Email</Trans>:
                </strong>
                {get(s, 'email', '') ? (
                  <a
                    href={`mailto:${get(s, 'email', '')}?Subject=Placeshaker`}
                    target="_top"
                  >
                    {get(s, 'email', '')}
                  </a>
                ) : null}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Address</Trans>:
                </strong>
                {s.lat && s.lng ? (
                  isMobileOnly ? (
                    <a
                      href={`https://www.waze.com/ul?ll=${s.lat}%2C${s.lng}&zoom=17`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  ) : (
                    <a
                      href={`https://www.google.com/maps/place/${get(
                        s,
                        'address',
                        ''
                      )}/@=${s.lat},${s.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  )
                ) : (
                  get(s, 'address', '')
                )}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Mobile</Trans>:
                </strong>
                <a href={`tel:${get(s, 'phone.mobile', '')}`}>
                  {get(s, 'phone.mobile', '')}
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    ));
  }

  render() {
    let { project } = this.state;

    return [
      <Card key={'assignSupervisor'} className="card-flex user-data">
        <CardHeader className={'text-center'}>
          <h6>
            <Trans>Supervisors</Trans> (
            {project.supervisors ? project.supervisors.length : 0})
          </h6>
        </CardHeader>

        <CardBody>
          {project.supervisors ? (
            <Slider slides={this.createSlider(project)} id={'supervisors'} />
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p className="text-center">
                  <Trans>Assign this project to a supervisor</Trans>
                </p>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    ];
  }
}

export default connect()(translate('translations-fr')(AssignSupervisor));
