import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { firestoreConnect } from 'react-redux-firebase';
import { ChatRoom } from '../../../components';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { UserData } from '../../../helpers/api';
import { chatNotification } from '../../../helpers/actions/projects';
import { getCookie } from '../../../helpers/cookies';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: null,
      user: null,
      open: false,
      messages: []
    };
  }

  componentDidMount() {
    UserData()
      .then(({ user }) => {
        this.setState({
          user,
          project: this.props.project,
          open: this.props.open
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentWillReceiveProps({ messages, project }) {
    if (messages.projects && messages.projects[project._id.toString()]) {
      this.setState(ps => ({
        ...ps,
        messages: messages.projects[project._id.toString()].messages
      }));
    }
  }

  createMessage(data) {
    let { firestore, project, messages } = this.props;

    if (!messages.projects[project._id.toString()]) {
      firestore
        .collection('projects')
        .doc(project._id.toString())
        .set({
          messages: []
        })
        .then(() => {
          this.addMessage([data], data);
        })
        .catch(error => {
          console.error('Error writing document: ', error);
        });
    } else {
      let ms = messages.projects[project._id.toString()].messages;
      ms.push(data);
      this.addMessage(ms, data);
    }
  }

  addMessage(ms, data) {
    let { firestore, project } = this.props;

    var docRef = firestore.collection('projects').doc(project._id.toString());
    docRef.update({ messages: ms });

    // call endpoint on the backend to send notification to all users in the project
    this.props
      .dispatch(chatNotification(project._id, data.text))
      .then(res => {})
      .catch(err => {
        console.log(err);
      });
  }

  deleteMessage(index, m) {
    let { firestore, project, messages } = this.props;

    let ms = messages.projects[project._id.toString()].messages;
    ms.splice(index, 1);

    var docRef = firestore.collection('projects').doc(project._id.toString());
    docRef.update({
      messages: ms
    });
  }

  render() {
    let { project, user, messages, open } = this.state;

    if (project && user) {
      return (
        <ChatRoom
          open={open}
          messages={messages}
          addMessage={data => this.createMessage(data)}
          deleteMessage={(index, m) => this.deleteMessage(index, m)}
          lastCheck={getCookie(`project_pilot_${project._id}`) || Date.now()}
          user={{
            photo: user.photoURL || defaultAvatar,
            name: user.displayName || 'User',
            info: '',
            id: user._id || null
          }}
          sendButton={{
            icon: <i className="fas fa-paper-plane fa-2x text-info"></i>,
            style: { background: 'transparent' }
          }}
          deleteButton={{
            icon: <i className="fas fa-trash-alt fa-lg text-danger"></i>,
            style: { background: 'transparent' }
          }}
          openButton={{
            icon: <i className="fas fa-comment-dots fa-2x text-info"></i>,
            style: { background: 'transparent' }
          }}
          hideButton={{
            icon: <i className="fas fa-times fa-2x text-white"></i>,
            style: { background: 'transparent' }
          }}
        />
      );
    }

    return null;
  }
}

export default compose(
  firestoreConnect(props => [
    { collection: 'projects', doc: props.project._id.toString() }
  ]),
  connect((state, props) => {
    return {
      messages: state.firestore.data,
      firestore: props.firestore
    };
  })
)(translate('translations-fr')(Chat));
