import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';

class TaskListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {}
    };
  }

  componentWillMount() {
    let { list } = this.props;
    this.setState(ps => ({ ...ps, list }));
  }

  cleanModal() {
    this.setState({
      nameValid: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { list } = this.state;

    this.setState({
      list: { ...list, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let name = this.name;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return name.validity.valid;
  }

  onSaveList() {
    let { list } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(list);
    }
  }

  render() {
    let { list } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'xs'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {list && list._id ? t('Edit List') : t('New List')}
        </ModalHeader>
        <ModalBody>
          {list ? (
            <Row>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.nameValid
                  }
                >
                  <Label>
                    <Trans>Name</Trans>
                  </Label>
                  <Input
                    type="text"
                    innerRef={node => (this.name = node)}
                    namevalid="nameValid"
                    value={list.name || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'name',
                        'nameValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveList(list)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(TaskListModal)
);
